import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/TabsView.vue';
import UnauthenticatedTabs from "@/views/UnauthenticatedTabs.vue";
import AuthenticatedTabs from "@/views/AuthenticatedTabs.vue";

import {GetResult, Preferences} from '@capacitor/preferences';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/DeliveryView.vue')
  },
  {
    path: '/delivery/:id',
    component: () => import('@/views/DeliveryItem.vue')
  },
  {
    path: '/ro/:id',
    component: () => import('@/views/ReadOnlyView.vue')
  },
  {
    path: '/tabs/internal/',
    component: Tabs,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import('@/views/ListView.vue')
      },
      {
        path: 'delivery/:id',
        component: () => import('@/views/DeliveryView.vue')
      },
      {
        path: 'deliveryItem/:id',
        component: () => import('@/views/DeliveryItem.vue')
      },
      {
        path: 'active',
        component: () => import('@/views/ActiveView.vue')
      },
      {
        path: 'closed',
        component: () => import('@/views/ClosedView.vue')
      }
    ]
  },
  {
    path: '/c/',
    component: UnauthenticatedTabs,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        redirect: 'unknown'
      },
      {
        path: 'd/:id',
        component: () => import('@/views/DeliveryView.vue')
      },
      {
        path: 'di/:id',
        component: () => import('@/views/DeliveryItem.vue')
      },
      {
        path: 'dk/:id',
        component: () => import('@/views/DeliveryKit.vue')
      },
      {
        path: 's/:id',
        component: () => import('@/views/SchematicsView.vue')
      },
      {
        path: 'h/:id',
        component: () => import('@/views/HistoryView.vue')
      },
      {
        path: 'c/:id',
        component: () => import('@/views/CommentsView.vue')
      },
      {
        path: 'm/:id',
        component: () => import('@/views/ManualsView.vue')
      },
      {
        path: 'sv/:id',
        component: () => import('@/views/ServiceView.vue')
      },
      {
        path: 'unknown',
        component: () => import('@/views/UnknownView.vue')
      }
    ]
  },
  {
    path: '/a/',
    component: AuthenticatedTabs,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: 'unknown'
      },
      {
          path: 'sv/conversation/:id',
          name: 'sv/conversation',
          component: () => import('@/views/ServiceConversationView.vue')
      },
      {
        path: 'sv/new/conversation/:id',
        name: 'sv/new/conversation',
        component: () => import('@/views/CreateServiceConversationView.vue')
      },
      {
        path: 'ai/',
        name: 'ai',
        component: () => import('@/views/AILabView.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const ret: Promise<GetResult> = Preferences.get({ key: 'user' });
  // eslint-disable-next-line
  ret.then( result => {
    /*let user;
    if (result.value != null) {
      user = result.value;
    }*/
    //console.log(to.matched.some(record => record.meta.requiresAuth));
    //console.log(user);
    //console.log(!user);
    //console.log(Object.keys(user).length === 0);
    next();
  })
})

export default router
