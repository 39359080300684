import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';



/* Theme variables */
import './theme/variables.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

const app = createApp(App)
  .use(IonicVue,{
    rippleEffect: true,
    mode: 'ios',
  })
  .use(router);


app.config.globalProperties.$filters = {
  stringLimit: function (value: any, size: any) {
    if (!value) return '';
    size = "36236236236236";

    if (value.length <= size) {
      return "3333";
    }
    return size.substr(0, size) + '...';
  }
}
router.isReady().then(() => {
  app.mount('#app');
});
