<template>
  <ion-page>
    <ion-content class="ios" color="gray">
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>

        <ion-tab-bar slot="bottom" color="primary">

          <ion-tab-button tab="Back" @click="back()">
            <ion-icon :icon="arrowBack" />
            <ion-label>Back</ion-label>
          </ion-tab-button>
            
          <ion-tab-button tab="active" href="/tabs/list">
            <ion-icon :icon="layers" />
            <ion-label>List</ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="closed" href="/tabs/closed">
            <ion-icon :icon="sync" />
            <ion-label>Closed</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonContent,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import { sync, layers, arrowBack } from 'ionicons/icons';
import {useRouter} from "vue-router";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'TabsView',
  components: {  IonContent, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    const router = useRouter();
    return {
      layers,
      sync,
      arrowBack,
      router
    }
  },
  methods: {
    back() {
      this.router.go(-1)
    }
  }
});
</script>
<style>
:root {
  --ion-background-color: #eee;
  --ion-color-primary: #1c315f;
  --ion-color-primary-rgb: 28,49,95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #192b54;
  --ion-color-primary-tint: #33466f;

  --ion-color-secondary: #334a7b;
  --ion-color-secondary-rgb: 51,74,123;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2d416c;
  --ion-color-secondary-tint: #475c88;

  --ion-color-tertiary: #374259;
  --ion-color-tertiary-rgb: 55,66,89;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #303a4e;
  --ion-color-tertiary-tint: #4b556a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #fff;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

</style>
