import axios from "axios";
import AuthService from "@/services/AuthService";
import {User} from "@/types/User";

class UserService {
    update(userArgs: object): Promise<User> {
        //console.log(`Trying to get the quote requests...`)
        console.log(`UserSerivce.update(), phone: ${JSON.stringify(userArgs)}`)
        const url = `${process.env.VUE_APP_API_URL}/v1/users/updateProfile`

        return new Promise<User>((resolve: any, reject: any) => {
            AuthService.getAuthorizationHeader().then( (headers) => {
                if(headers){
                    //console.log(`About to send the following headers: ${JSON.stringify(headers)}`);
                    axios.patch(url, userArgs, headers as any).then( (response) => {
                        console.log(`Received the following response from updateSelf service: ${response.data.phone_number}`)
                            resolve(response.data as User);
                        }).catch((error) => {
                            reject(error);
                        });
                } else {
                    reject('Headers never received.');
                }
            }).catch((error) => {
                reject(error);
            });
        })
    }
}
export default new UserService();
