<template>
  <meta name="mobile-web-app-capable" content="yes">
  <meta name="apple-mobile-web-app-capable" content="yes">

<!-- possible content values: default, black or black-translucent -->
  <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
  <ion-page>
    <ion-content class="ios" color="primary">
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>

        <ion-tab-bar slot="bottom" color="primary">

          <ion-tab-button tab="Back" @click="back()" class="ion-margin-start">
            <ion-icon :icon="arrowBack" />
            <ion-label>Back</ion-label>
          </ion-tab-button>

          <!--<ion-tab-button @click="openUserPrefs()">
            <ion-icon :icon="person" />
            <ion-label>User</ion-label>
          </ion-tab-button>-->

          <ion-tab-button tab="Delivery" @click="goToDelivery(this.uuid)" class="ion-margin-start">
            <ion-icon :icon="cube" />
            <ion-label>This Delivery</ion-label>
          </ion-tab-button>

          <ion-searchbar :debounce="1000" autocomplete="on" @ionChange="searchInputChange($event)" class="search_style ion-margin-start"></ion-searchbar>

        </ion-tab-bar>
      </ion-tabs>

      <ion-modal ref="modal" :isOpen="loginModalVisible" backdropDismiss="false">
        <ion-header>
          <ion-toolbar color="primary">
            <ion-title>Please enter your information before proceeding</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item color="light">
            <ion-label position="stacked">Name:</ion-label>
            <ion-input ref="input" type="text" name="name" v-model="this.currentUserName"></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label position="stacked">Phone Number:</ion-label>
            <ion-input ref="input" type="text" name="phoneNumber" v-model="this.currentUserPhone"></ion-input>
          </ion-item>

          <ion-item color="light">
            <ion-buttons slot="end">
              <ion-button color="primary" :strong="true" @click="saveUserModal()">Confirm</ion-button>
            </ion-buttons>
          </ion-item>

        </ion-content>
      </ion-modal>

      <ion-modal ref="modal" :isOpen="searchModalVisible" @didDismiss="dismissSearchResults"  backdropDismiss="true">
        <ion-header>
          <ion-toolbar color="primary">
            <ion-title>{{this.searchResults.length}} Kits and Items</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="dismissSearchResults()">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <div v-for="searchResult in searchResults" :key="searchResult.id">
            <ion-item v-if="searchResult.type == 'item'" @click="goToDeliveryItem(searchResult.id)" color="light">Item {{searchResult.number}}</ion-item>
            <ion-item v-if="searchResult.type == 'kit'" @click="goToDeliveryKit(searchResult.id)" color="light">Kit {{searchResult.number}}</ion-item>
          </div>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonSearchbar,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonTitle,
  IonToolbar,
  onIonViewWillEnter
} from '@ionic/vue';
import { sync, layers, arrowBack, cube, person } from 'ionicons/icons';
import {useRoute, useRouter} from "vue-router";
import {defineComponent} from "vue";
import { Preferences } from '@capacitor/preferences';
import DeliveryDataService from "@/services/DeliveryDataService";

export default defineComponent({
  name: 'UnauthenticatedTabs',
  data: () => {
    return {
      prefs: {} as any,
      uuid: useRoute().params.id as string,
      loginModalVisible: false as boolean,
      searchModalVisible: false as boolean,
      searchResults: [] as any[],
      currentUserName: "" as string,
      currentUserPhone: "" as string
    };
  },
  components: {  IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonInput, IonItem, IonModal, IonPage, IonRouterOutlet, IonSearchbar, IonTitle, IonToolbar },
  created() {
    //this.prefs = Preferences;
    this.loadUserData();
  },
  setup() {
    onIonViewWillEnter(() => {
      //console.log('Entered tab! REMEMBER THIS FOR LATER!!!!!!');
    });
    const router = useRouter();
    return {
      layers,
      sync,
      arrowBack,
      cube,
      person,
      router
    }
  },
  methods: {
    openUserPrefs(){
      //console.log(this.prefs);
    },
    back() {
      this.router.go(-1)
    },
    goToDelivery(deliveryUUID: string){
      //console.log("Going to delivery " + deliveryUUID + "!");
      this.router.push('../d/' + deliveryUUID);
    },
    goToDeliveryKit(id: string){
      this.dismissSearchResults();
      this.router.push('../dk/' + id);
    },
    goToDeliveryItem(id: string){
      this.dismissSearchResults();
      this.router.push('../di/' + id);
    },
    searchInputChange(searchStuff: any){
      console.log("Show search results? " + this.searchModalVisible + " , result count? " + this.searchResults.length);
      if(searchStuff.detail.value != ""){
        Preferences.get({ key: 'delivery_uuid' }).then((result) => {
          if(result.value != null){
            const searchString = searchStuff.detail.value;
            const uuid = result.value.toString().replaceAll('"', '');
            console.log(`DeliveryDataService.search( ${uuid},${searchString}`)
            DeliveryDataService.search( uuid,searchString,(error: any, results: any) => {
              //console.log(error);
              if(results.length > 0){
                this.searchModalVisible = true;
                this.searchResults = results;
                console.log("Show search results FINALLY? " + this.searchModalVisible + " , result count? " + this.searchResults.length);
              } else {
                console.log("Show search results FINALLY? " + this.searchModalVisible + " , result count? " + this.searchResults.length);
                this.searchModalVisible = false;
                this.searchResults = [];
              }
              console.log(results);
            });
          }
        });
      } else {
        this.searchModalVisible = false;
        this.searchResults = [];
      }
    },
    saveUserModal(){
      //console.log(this.currentUserName);
      //console.log(this.currentUserPhone);
      Preferences.set({key: 'user',value: JSON.stringify(this.currentUserName)});
      Preferences.set({key: 'phone',value: JSON.stringify(this.currentUserPhone)});
      //this.storage.set('phone',this.currentUserPhone);
      //this.loginModalVisible = false;
      //console.log(Preferences);
      this.loginModalVisible = false;
    },
    loadUserData(){
      Preferences.get({ key: 'user' }).then((result) => {
        if(result.value != null){
          //console.log(result.value);
          this.currentUserName = result.value.toString().replaceAll('"', '');
        } else {
          console.log("User was null!");
        }
        this.displayLoginPrompt();
      });
      Preferences.get({ key: 'phone' }).then((result) => {
        if(result.value != null){
          //console.log(result.value);
          this.currentUserPhone = result.value.toString().replaceAll('"', '');
        } else {
          console.log("Phone was null!");
        }
      });
    },
    dismissSearchResults(){
      this.searchModalVisible = false;
      this.searchResults = [];
    },
    displayLoginPrompt(){
      if(this.currentUserName == ""){
        //console.log("I JUST SET THE display login prompt to TRUE because this.currentUserName was null: " + this.currentUserName);
        this.loginModalVisible = true;
      } else {
        //console.log("I JUST SET THE display login prompt to false because this.currentUserName was NOT null: " + this.currentUserName);
        this.loginModalVisible = false;
      }
    }
  }
});
</script>
<style>
:root {
  --ion-background-color: #eee;
  --ion-color-primary: #1c315f;
  --ion-color-primary-rgb: 28,49,95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #192b54;
  --ion-color-primary-tint: #33466f;

  --ion-color-secondary: #334a7b;
  --ion-color-secondary-rgb: 51,74,123;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2d416c;
  --ion-color-secondary-tint: #475c88;

  --ion-color-tertiary: #374259;
  --ion-color-tertiary-rgb: 55,66,89;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #303a4e;
  --ion-color-tertiary-tint: #4b556a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #990000;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #fff;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

</style>
