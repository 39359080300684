import {DeliveryItem} from "@/types/DeliveryItem";
import { DateTime } from 'luxon';

class UtilityService {
    determineColor(deliveryItem: DeliveryItem) {
        if(deliveryItem.quantity_shipped > deliveryItem.quantity_received_iwo){
            return "danger";
        } else {
            return "light";
        }
    }
    isDeliveryMissingPieces(deliveryItem: DeliveryItem){
        let result = false;
        if(deliveryItem && deliveryItem.quantity_shipped && deliveryItem.quantity_received_iwo){
            //console.log("Made it into the inner condition!" + deliveryItem.number);
            //console.log("deliveryItem.quantity_shipped: " + deliveryItem.quantity_shipped)
            //console.log("deliveryItem.quantity_received_iwo: " + deliveryItem.quantity_received_iwo)
            if(deliveryItem.quantity_shipped > deliveryItem.quantity_received_iwo){
                result = true;
            }
        } else if (deliveryItem && deliveryItem.quantity_shipped && !deliveryItem.quantity_received_iwo){
            result = true;
        }
        return result;
    }
    formatDate(incomingDate: string) {
        if (incomingDate) {
            return DateTime.fromISO(incomingDate).toFormat("MMMM, d yyyy");
        } else {
            return null;
        }
    }
    formatISODate(incomingDate: string) {
        if (incomingDate) {
            return DateTime.fromISO(incomingDate).toFormat("yyyy-MM-d");
        } else {
            return "";
        }
    }
    formatDateShort(incomingDate: string) {
        if (incomingDate) {
            return DateTime.fromISO(incomingDate).toFormat("M/d/yy");
        } else {
            return null;
        }
    }
    formatDateTime(incomingDate: any) {
        if (incomingDate) {
            let returnValue;
            try {
                returnValue = DateTime.fromISO(incomingDate).toFormat("MMMM d, yyyy t");
            } catch (e) {
                returnValue = incomingDate;
            }
            return returnValue;
        } else {
            return null;
        }
    }
    formatTime(incomingDate: string) {
        if (incomingDate) {
            let returnValue;
            try {
                returnValue = DateTime.fromISO(incomingDate).toFormat("h:mm a");
            } catch (e) {
                returnValue = incomingDate;
            }
            return returnValue;
        } else {
            return null;
        }
    }
    getLongCurrentDate(){
        const now = DateTime.now();
        return this.formatDateTime(now);
    }
    validatePhone(phone: string) {
        const stripped = this.stripAllButNumbers(phone)
        if (/^\d{10}$/.test(stripped) || /^\d{11}$/.test(stripped)) {
            console.log(`${this.stripAllButNumbers(stripped)} appears to be a valid number.`)
            return true;
        } else {
            console.log(`${stripped} appears to NOT be a valid number`)
            return false;
        }
    }
    addOneToFrontIf9Digits(incoming: string){
        if(incoming && incoming.length == 10){
            incoming = `1${incoming}`
        }
        return incoming;
    }
    stripAllButNumbers(incoming: string){
        //console.log(`Trying to strip out the numbers from the following: ${incoming}`)
        const stripped = incoming.replace(/[^\d.]+/g, "")
        if(stripped){
            //console.log(`Returning stripped: ${stripped}`)
            return this.addOneToFrontIf9Digits(stripped);
        } else {
            return "";
        }
    }
    formatPhoneNumber(inNumber: string) {
        if (inNumber) {
            let returnValue: string = inNumber.replace(/\D/g, "");
            if (returnValue.length == 11) {
                returnValue = returnValue.replace(
                    /(\d{1})(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3-$4"
                );
            }
            if (returnValue.length == 10) {
                returnValue = returnValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            if (returnValue.length == 7) {
                returnValue = returnValue.replace(/(\d{3})(\d{4})/, "$1-$2");
            }
            return returnValue;
        } else {
            return "";
        }
    }
    convertHoursToDays(inNumber: any): number{
        const hours = parseInt(inNumber);
        let days = 0 as number;
        if (hours>24){
            days = parseInt(String(hours / 24));
        }
        return days;
    }
    makeImageUrl(uuid: string){
        return `${process.env.VUE_APP_API_URL}/mobile/f/${uuid}`
    }
    formatCurrency(input: number){
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
        return formatter.format(input)
    }
}
export default new UtilityService();