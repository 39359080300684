import { DateTime } from 'luxon';
import SessionService from "@/services/SessionService";
import axios from "axios";
import Router from "@/router";
import type {User} from "@/types/User";
import {Preferences} from "@capacitor/preferences";

class AuthService {
    async isAuthenticated() {
        return new Promise<boolean>((resolve, reject) => {
            //console.log(`In isAuthenticated()`)
            Preferences.get({ key: 'user' }).then((u) => {
                const user = u.value as unknown as User;
                //console.log(user);
                Preferences.get({ key: 'expires_at' }).then((expiresAt: any) => {
                    //console.log(`About to see if this user is expired: ${expiresAt.value}`)
                    //expires_at = DateTime.fromFormat( expires_at.value,  );
                    //Tue Jul 18 2023 08:49:14 GMT-0500 (Central Daylight Time)
                    expiresAt = DateTime.fromJSDate( new Date(expiresAt.value) );
                    const now = DateTime.local();
                    const secondsUntilExpired = expiresAt.diff(now, "seconds").seconds;
                    //console.log(`How many seconds until our session is expired? ${secondsUntilExpired}`)
                    if (secondsUntilExpired > 0 && Object.keys(user).length != 0) {
                        resolve(true);
                        //console.log(`This user was not expired so our authentication is: ${authenticated}`)
                    } else {
                        resolve(false);
                        //console.log(`This user was expired or not even a user so our authentication is: ${authenticated}`)
                    }
                });
            }).catch( (exc) => {
                reject(exc);
            });
        });
    }
    authenticate(email: string, otp: string, callback: any) {
        const url = `${process.env.VUE_APP_API_URL}/auth/token`;
        axios
            .post(url, {
                // eslint-disable-next-line @typescript-eslint/camelcase
                grant_type: "otp",
                // eslint-disable-next-line @typescript-eslint/camelcase
                client_id: email,
                // eslint-disable-next-line @typescript-eslint/camelcase
                client_secret: otp,
            })
            .then((response: any) => {
                //console.log(response.data.user);
                Preferences.set({key: 'user',value: JSON.stringify(response.data.user)}).then( () => {
                    Preferences.set({key: 'access_token',value: response.data.access_token}).then( () => {
                        Preferences.set({key: 'refresh_token',value: response.data.refresh_token}).then( () => {
                            let expiresAt: any = "";
                            expiresAt = DateTime.local().plus({ seconds: parseInt(response.data.expires_in) }).toJSDate();
                            Preferences.set({key: 'expires_at',value: expiresAt}).then(()=> {
                                callback(null,true);
                            });
                        });
                    });
                });

            })
            .catch((error: any) => {
                callback(error, null);
            });
    }
    getAuthorizationHeader() {
        //console.log(`In getAuthorizationHeader header!`);
        const url = `${process.env.VUE_APP_API_URL}/auth/refresh`;
        let headers = {};

        const FIVE_MINUTES_IN_SECONDS = 300;

        return new Promise<object>((resolve, reject) => {
            Preferences.get({ key: 'access_token' }).then((at) => {
                //console.log(`We had access_token! ${JSON.stringify(at.value)}`);
                if (at.value) {
                    //console.log(`We had at.value! ${JSON.stringify(at.value)}`)
                    const accessToken = at.value;
                    Preferences.get({ key: 'refresh_token' }).then((rt) => {
                        //console.log(`We found the refresh token: ${JSON.stringify(rt)}`)
                        if(rt.value){
                            //console.log(`We had rt.value! ${JSON.stringify(rt.value)}`)
                            const refreshToken = rt.value;
                            //console.log(`We had refresh_token! ${JSON.stringify(refreshToken)}`);

                            Preferences.get({ key: 'expires_at' }).then((ea: any) => {

                                //console.log(`We had expires_at! ${JSON.stringify(ea.value)}`);

                                const expiresAt = DateTime.fromJSDate( new Date(ea.value) );
                                //const expires_at = DateTime.fr fromJSDate(ea.value);
                                //console.log(expiresAt);

                                const now = DateTime.local();
                                const secondsUntilExpired = expiresAt.diff(now, "seconds").seconds;
                                //console.log(secondsUntilExpired);
                                //console.log(`if (seconds_until_expired > FIVE_MINUTES_IN_SECONDS) { ${JSON.stringify(secondsUntilExpired)} > ${JSON.stringify(FIVE_MINUTES_IN_SECONDS)}`);
                                if (secondsUntilExpired > FIVE_MINUTES_IN_SECONDS) {
                                    //console.log(`if (seconds_until_expired > FIVE_MINUTES_IN_SECONDS) { ${JSON.stringify((secondsUntilExpired > FIVE_MINUTES_IN_SECONDS))}`);
                                    headers = {
                                        headers: {
                                            Authorization: "Bearer " + accessToken,
                                        },
                                    };

                                    resolve(headers);
                                } else {
                                    //console.log(`else...time to refresh using token: ${refreshToken}`);

                                    const body = {
                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                        grant_type: "refresh_token",
                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                        refresh_token: refreshToken,
                                    };

                                    axios.post(url, body)
                                        .then((resp) => {
                                            // eslint-disable-next-line
                                            Preferences.remove({key: 'access_token'}).then( (at) => {
                                                //console.log(`Preferences.remove({key: 'access_token'} { ${JSON.stringify(at)}`);
                                                // eslint-disable-next-line
                                                Preferences.remove({key: 'refresh_token'}).then( (rt) => {
                                                    //console.log(`Preferences.remove({key: 'refresh_token'}).then'} { ${JSON.stringify(rt)}`);
                                                    // eslint-disable-next-line
                                                    Preferences.remove({key: 'expires_at'}).then( (ea) => {
                                                        //console.log(`Preferences.remove({key: 'expires_at'}).then( (ea) ${JSON.stringify(ea)}`);
                                                        Preferences.set( {key: 'access_token', value: resp.data.access_token}).then( () => {
                                                            //console.log(`Preferences.set( {key: 'access_token', value: resp.data.access_token}) ${JSON.stringify(true)}`);
                                                            Preferences.set( {key: 'refresh_token', value: resp.data.refresh_token}).then( () => {
                                                                //console.log(`Preferences.set( {key: 'refresh_token', value: resp.data.refresh_token}) ${JSON.stringify(true)}`);
                                                                let expiresAt: any = "";
                                                                expiresAt = DateTime.local().plus({ seconds: parseInt(resp.data.expires_in) }).toJSDate();
                                                                Preferences.set({key: 'expires_at',value: expiresAt}).then(()=> {
                                                                    headers = {
                                                                        headers: {
                                                                            Authorization: "Bearer " + resp.data.access_token,
                                                                        },
                                                                    };
                                                                    //console.log(`WE MADE IT TO THE FINAL CALLBACK! ${headers}`)
                                                                    resolve(headers);
                                                                });
                                                            }).catch((e) => {
                                                                reject(e)
                                                            });
                                                        }).catch((e) => {
                                                            reject(e)
                                                        });
                                                    }).catch( (er) => {
                                                        reject(er)
                                                    });
                                                }).catch( (err) => {
                                                    reject(err)
                                                });
                                            }).catch( (error) => {
                                                reject(error)
                                            });
                                        })
                                        .catch(() => {
                                            SessionService.setItemJSON("postAuthURL", Router.currentRoute.value.path);
                                            SessionService.logOut();
                                            //console.log(`An error was caught (${error}). It's about to re-route to login.`);
                                            //window.location.href = "/otp";
                                            reject("Error getting refresh token")
                                        });
                                }
                            }).catch( (ex) => {
                                reject(ex);
                            });

                        } else {
                            //console.log('WE DID NOT HAVE A REFRESH TOKEN.')
                        }
                    }).catch( (ex) => {
                        //console.log(`Did an error happen getting refresh token?`)
                        reject(ex);
                    });
                } else {
                    //console.log(`Crap. The Callback was never returned. at.value was null`)
                    reject("Not authenticated.")
                }
            }).catch( (exc) => {
                reject(exc);
            });
        });
    }
    canEmailViewEntity(email: string, id: string, entityType: string, callback: any) {
        console.log(`canEmailViewEntity(${email},${id},${entityType}`)
        const url = `${process.env.VUE_APP_API_URL}/auth/canEmailViewEntity/${entityType}/${id}/${email}`;
        axios.get(url, {})
            .then((response: any) => {
                callback(null, response);
            })
            .catch((error: any) => {
                callback(error, null);
            });
    }
    canEmailEditEntity(email: string, id: string, entityType: string, callback: any) {
        console.log(`canEmailEditEntity(${email},${id},${entityType}`)
        const url = `${process.env.VUE_APP_API_URL}/auth/canEmailEditEntity/${entityType}/${id}/${email}`;
        axios.get(url, {})
            .then((response: any) => {
                callback(null, response);
            })
            .catch((error: any) => {
                callback(error, null);
            });
    }
    getViewersForEntity(entityType: string, id: string, callback: any){
        this.getAuthorizationHeader().then( (headers) => {
            if(headers){
                const url = `${process.env.VUE_APP_API_URL}/auth/getViewersForEntity/${entityType}/${id}`;
                axios.get(url, headers as any)
                    .then((response: any) => {
                        console.log(`Viewers below...`);
                        console.log(response.data);
                        callback(null, response.data);
                    })
                    .catch((error: any) => {
                        callback(error, null);
                    });
            } else {
                console.log(`Headers never received...`);
            }
        });
    }
    getEditorsForEntity(entityType: string, id: string, callback: any){
        this.getAuthorizationHeader().then( (headers) => {
            if(headers){
                const url = `${process.env.VUE_APP_API_URL}/auth/getEditorsForEntity/${entityType}/${id}`;
                axios.get(url, headers as any)
                    .then((response: any) => {
                        callback(null, response.data);
                    })
                    .catch((error: any) => {
                        callback(error, null);
                    });
            } else {
                console.log(`Headers never received...`);
            }
        });
    }
    addViewerForEntity(entityType: string, id: string, email: string, callback: any){
        this.getAuthorizationHeader().then( (headers) => {
            if(headers){
                console.log(`Had headers and are sending it! ${JSON.stringify(headers)}`)
                const url = `${process.env.VUE_APP_API_URL}/auth/addViewerForEntity/${entityType}/${id}/${email}`;
                axios.post(url, {}, headers as any)
                    .then((response: any) => {
                        callback(null, response.data);
                    })
                    .catch((error: any) => {
                        callback(error, null);
                    });
            } else {
                console.log(`Headers never received...`);
            }
        });
    }
    addEditorForEntity(entityType: string, id: string, email: string, callback: any){
        this.getAuthorizationHeader().then( (headers) => {
            if(headers){
                console.log(`Had headers and are sending it! ${JSON.stringify(headers)}`)
                const url = `${process.env.VUE_APP_API_URL}/auth/addEditorForEntity/${entityType}/${id}/${email}`;
                axios.post(url, {}, headers as any)
                    .then((response: any) => {
                        callback(null, response.data);
                    })
                    .catch((error: any) => {
                        callback(error, null);
                    });
            } else {
                console.log(`Headers never received...`);
            }
        });
    }
    removeUserFromEntity(id: string, callback: any){
        this.getAuthorizationHeader().then( (headers) => {
            if(headers){
                console.log(`Had headers and are sending it! ${JSON.stringify(headers)}`)
                const url = `${process.env.VUE_APP_API_URL}/auth/removeUserForEntity/${id}`;
                axios.delete(url, headers as any)
                    .then((response: any) => {
                        callback(null, response.data);
                    })
                    .catch((error: any) => {
                        callback(error, null);
                    });
            } else {
                console.log(`Headers never received...`);
            }
        });
    }
    generateOTP(email: string, callback: any) {
        const url = `${process.env.VUE_APP_API_URL}/auth/generate_otp/${email}`;
        axios.get(url, {})
            .then((response: any) => {
                callback(null, response);
            })
            .catch((error: any) => {
                console.log(error);
                alert(`generate error!`);
                callback(error, null);
            });
    }
    findOrCreateUserAndAuthenticateWithOTP(user: User, callback: any){
        const url = `${process.env.VUE_APP_API_URL}/noauth/findOrCreateUserAndAuthenticate/`;
        axios.post(url, user)
            .then((response: any) => {
                callback(null, response);
            })
            .catch((error: any) => {
                console.log(error);
                callback(error, null);
            });
    }
}
export default new AuthService();