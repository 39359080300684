<template>
  <meta name="mobile-web-app-capable" content="yes">
  <meta name="apple-mobile-web-app-capable" content="yes">

<!-- possible content values: default, black or black-translucent -->
  <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">
  <ion-page>

    <ion-content class="ios" color="primary">
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom" color="primary">
          <!--<ion-tab-button tab="Delivery" @click="goToDelivery(this.uuid)" class="ion-margin-start">
            <ion-icon :icon="cube" />
            <ion-label>This Delivery</ion-label>
          </ion-tab-button>-->

          <!-- <ion-searchbar :debounce="1000" autocomplete="on" @ionChange="searchInputChange($event)" class="search_style ion-margin-start"></ion-searchbar> -->

          <ion-tab-button @click="openUserPrefs()">
            <ion-icon :icon="person" />
            <ion-label>{{ (user.first_name != '' ? user.first_name : 'User') }}</ion-label>
          </ion-tab-button>
          <ion-tab-button @click="manageUsers()" v-if="canManageUsers && 1 === 3">
            <ion-icon :icon="people" />
            <ion-label>Manage Access</ion-label>
          </ion-tab-button>
          <ion-tab-button @click="logOff()">
            <ion-icon :icon="logOut" />
            <ion-label>Logout</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>


      <ion-modal ref="modal" :isOpen="otpModalVisible" backdropDismiss="false">
        <ion-header>
          <ion-toolbar color="primary">
            <ion-title>Enter the six digit code you just received</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item color="light" lines="none">
          <div ref="otpCont" id="otp_container">
            <otp
            :digit-count="6"
            @update:otp="otpValue = $event"
          ></otp>
          </div>
          </ion-item>
          <ion-item color="light" lines="none">
          <p>If you prefer to receive your one time password via text message in the future you can adjust your authentication preferences. To do this, authenticate and then click on your profile at the bottom of the screen.</p>
          </ion-item>
          <br />
          <ion-item color="light" lines="none">
            <ion-button :disabled="otpValue.length != 6" @click="authenticate()" slot="end">Confirm</ion-button>
          </ion-item>

        </ion-content>
      </ion-modal>


      <ion-modal ref="modal" :isOpen="loginModalVisible" backdropDismiss="false">
        <ion-header>
          <ion-toolbar color="primary">
            <ion-title v-if="!createUser">Please enter your email address</ion-title>
            <ion-title v-if="createUser">Please enter your information</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding" v-if="!createUser">

          <ion-item color="light" lines="none" class="roundedInput">
            <ion-input label="Email" ref="input" type="text" name="email" v-model="this.currentEmail"></ion-input>
          </ion-item>
          <br />
          <ion-item color="light" lines="none">
            <ion-buttons slot="start">
              <ion-button color="primary" :strong="true" @click="submitEmailAddress()">Confirm</ion-button>
            </ion-buttons>
          </ion-item>

          <ion-card color="light">
            <ion-card-content>
              <p>This page is only available to users with a registered email. If you do not have access to this page and need it please contact support@oscocontrols.com.<br /><br />If you have logged in previously and have entered a valid phone number you can choose to receive your password via SMS message instead of email. </p>
              <p>If you have never registered with this system, please choose "Create User" below. A user will be automatically created for you after you input your name and email address.</p>
              <ion-buttons slot="start">
                <ion-button color="primary"  @click="showUserRegistration()">Create User</ion-button>
              </ion-buttons>
            </ion-card-content>
          </ion-card>
        </ion-content>
        <ion-content v-if="createUser">
          <ion-card color="light">
            <ion-card-content>
              <ion-item color="light" lines="none" class="roundedInput">
                <ion-label>First Name</ion-label>
                <ion-input ref="input" type="text" name="email" v-model="currentUserFirstName"></ion-input>
              </ion-item>
              <ion-item color="light" lines="none" class="roundedInput">
                <ion-label>Last Name</ion-label>
                <ion-input ref="input" type="text" name="email" v-model="currentUserLastName"></ion-input>
              </ion-item>
              <ion-item color="light" lines="none" class="roundedInput">
                <ion-label>Email Address</ion-label>
                <ion-input ref="input" type="text" name="email" v-model="currentEmail"></ion-input>
              </ion-item>
              <ion-item  color="light" lines="none" class="roundedInput" >
                <ion-label>Phone Number:</ion-label>
                <ion-input ref="input" type="text" v-model="currentUserPhone"></ion-input>
              </ion-item>
              <ion-item color="light" lines="none" v-show="currentUserPhone && currentUserPhone.length > 0">
                <ion-label position="stacked">Preferred Communication:</ion-label>
                <ion-select label="Preferred Comm:" v-model="currentUserPreferredComm">
                  <ion-select-option value="0">Email</ion-select-option>
                  <ion-select-option value="1">Phone / Text Message</ion-select-option>
                </ion-select>
              </ion-item>
              <br />
              <ion-item color="light" lines="none">
                <ion-buttons slot="start">
                  <ion-button color="primary" :strong="true" @click="submitNewUserAndAuthenticate()">Submit New User and Authenticate</ion-button>
                </ion-buttons>
              </ion-item>

            </ion-card-content>
            <ion-item color="light" lines="none" class="roundedInput">

            </ion-item>

            <br />
          </ion-card>
        </ion-content>
      </ion-modal>

      <ion-modal ref="modal" :isOpen="editUserVisible" backdropDismiss="false">
        <ion-toolbar color="primary">
          <ion-title>Your Info</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="updateUser()" slot="end" fill="solid" color="dark">Save</ion-button>
            <ion-button @click="cancelUpdateSelf()" slot="end" fill="solid" color="dark">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>


        <ion-content class="ion-padding">
          <ion-item  color="light">
            <ion-label position="stacked">First Name:</ion-label>
            <ion-input ref="input" type="text" v-model="currentUserFirstName"></ion-input>
          </ion-item>
          <ion-item  color="light">
            <ion-label position="stacked">Last Name:</ion-label>
            <ion-input ref="input" type="text" v-model="currentUserLastName"></ion-input>
          </ion-item>
          <ion-item  color="light">
            <ion-label position="stacked">11 Digit Phone Number (e.g. 1.555.310.9140):</ion-label>
            <ion-input ref="input" type="text" v-model="currentUserPhone"></ion-input>
          </ion-item>
          <ion-item color="light" lines="none" v-show="currentUserPhone && currentUserPhone.length > 0">
            <ion-label position="stacked">Preferred Communication:</ion-label>
            <ion-select label="Preferred Comm:" v-model="currentUserPreferredComm">
              <ion-select-option value="0">Email</ion-select-option>
              <ion-select-option value="1">Phone / Text Message</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item color="light" lines="none" class="roundedInput">
            <ion-label position="stacked">Email Address</ion-label>
            <ion-input type="email" enabled="false">{{currentEmail}}</ion-input>
          </ion-item>
        </ion-content>
      </ion-modal>

      <ion-modal ref="modal" :isOpen="editAccessVisible" backdropDismiss="false">
        <ion-toolbar color="primary">
          <ion-title>Manage User Access</ion-title>
          <ion-buttons slot="end">
            <ion-button  @click="cancelUpdateUsers()" slot="end" fill="solid" color="dark">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>

        <ion-content class="ion-padding">
          <ion-card color="light" class="ion-padding">
            <ion-card-header>
              <ion-title>Viewers (read only)</ion-title>
            </ion-card-header>
            <ion-card-content>
              <ion-item  color="light" v-for="ea in viewers" :key="ea.id">
                {{ ea.email }} <ion-button @click="removeUser(ea.id)" slot="end">Remove</ion-button>
              </ion-item>
              <ion-item  color="light">
                <ion-label>New viewer:</ion-label>
                <ion-input ref="input" type="text" v-model="newViewerEmail"></ion-input>
                <ion-button @click="addViewer()">Add</ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card>

          <ion-card color="light" class="ion-padding">
            <ion-card-header>
              <ion-title>Editors (Can modify users and data)</ion-title>
            </ion-card-header>
            <ion-card-content>
              <ion-item  color="light" v-for="ea in editors" :key="ea.id">
                {{ ea.email }} <ion-button @click="removeUser(ea.id)" slot="end">Remove</ion-button>
              </ion-item>
              <ion-item  color="light">
                <ion-label>New editor:</ion-label>
                <ion-input ref="input" type="text" v-model="newEditorEmail"></ion-input>
                <ion-button @click="addEditor()">Add</ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card>

        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import otp from "../components/OTPComponent.vue";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonTitle,
  IonToolbar,
  IonSelectOption, IonSelect, toastController
} from '@ionic/vue';
import { sync, layers, people, logOut, person } from 'ionicons/icons';
import {useRoute, useRouter} from "vue-router";
import {defineComponent} from "vue";
import { Preferences } from '@capacitor/preferences';
import AuthService from "@/services/AuthService";
import validator from "email-validator";
import type { User } from "@/types/User";
import UserService from "@/services/UserService";
import UtilityService from "@/services/UtilityService";
import SessionService from "@/services/SessionService";
import {EntityAuthorization} from "@/types/EntityAuthorization";

export default defineComponent({
  name: 'AuthenticatedTabs',
  data: () => {
    return {
      prefs: {} as any,
      authenticated: false,
      uuid: useRoute().params.id as string,
      finalRoute: '',
      currentRoute : useRouter().currentRoute.value.name as string,
      user: {} as User,
      canManageUsers: false,
      editAccessVisible: false,
      editors: [] as EntityAuthorization[],
      viewers: [] as EntityAuthorization[],
      newViewerEmail: '',
      newEditorEmail: '',
      loginModalVisible: false as boolean,
      searchModalVisible: false as boolean,
      otpModalVisible: false as boolean,
      searchResults: [] as any[],
      currentEmail: "" as string,
      currentUserPhone: "" as string,
      currentUserFirstName: "" as string,
      currentUserLastName: "" as string,
      currentUserPreferredComm: '0',
      editUserVisible: false,
      digits: [],
      digitCount: 6,
      otpCount: 0,
      isToastOpen: false,
      createUser: false,
      toastMessage: "" as string,
      otpValue: '' as string,
    };
  },
  components: {  IonButton, IonButtons, IonCard, IonCardHeader, IonCardContent, IonContent, IonHeader, IonLabel, IonSelect, IonSelectOption, IonTabs, IonTabBar, IonTabButton, IonIcon, IonInput, IonItem, IonModal, IonPage, IonRouterOutlet, IonTitle, IonToolbar, otp },

  mounted(){
    this.finalRoute = this.currentRoute;
    if (this.currentRoute.includes('/')){
      this.finalRoute = this.currentRoute.split('/')[0]
    }
    AuthService.isAuthenticated().then( (response) => {
      this.authenticated = response;
      if(!this.authenticated){
        this.loginModalVisible = true;
      } else {
        Preferences.get({ key: 'user' }).then((user: any) => {
          this.user = JSON.parse(user.value) as User;
          if(this.user.email){
            AuthService.canEmailEditEntity( this.user.email,this.uuid, this.finalRoute, (error: any) => {
              if(error){
                this.canManageUsers = false;
              } else {
                // this would error out with 403 error if we could not edit
                this.canManageUsers = true;
              }
            });
          }
        });
      }
    }).catch( (exc) => {
      console.log(`Error checking if authenticated: ${exc}`);
    });
  },
  setup() {
    const router = useRouter();
    return {
      layers,
      sync,
      person,
      people,
      logOut,
      router
    }
  },
  methods: {
    removeUser(id: string){
      AuthService.removeUserFromEntity( id, (error: any, results: any) => {
        if(error){
          toastController.create({ duration: 5000, color: 'warning', message: `Error removing authorization.`, position: 'top' }).then(toast => toast.present());
        }
        if(results){
          this.loadViewersAndUsers();
        }
      });

      console.log(`removeViewer(${id})`)
    },
    addViewer(){
      if(!validator.validate(this.newViewerEmail)){
        toastController.create({ duration: 5000, color: 'warning', message: `${this.newViewerEmail} is not a valid email address.`, position: 'top' }).then(toast => toast.present());
      } else {
        AuthService.addViewerForEntity( this.finalRoute,'*', this.newViewerEmail, (error: any, results: any) => {
          if(error){
            toastController.create({ duration: 5000, color: 'warning', message: `Error creating authorization for ${this.newViewerEmail}`, position: 'top' }).then(toast => toast.present());
          }
          if(results){
            this.newViewerEmail = '';
            this.loadViewers();
          }
        });
      }
    },
    addEditor(){
      if(!validator.validate(this.newEditorEmail)){
        toastController.create({ duration: 5000, color: 'warning', message: `${this.newEditorEmail} is not a valid email address.`, position: 'top' }).then(toast => toast.present());
      } else {
        AuthService.addEditorForEntity( this.finalRoute,'*', this.newEditorEmail, (error: any, results: any) => {
          if(error){
            toastController.create({ duration: 5000, color: 'warning', message: `Error creating authorization for ${this.newEditorEmail}`, position: 'top' }).then(toast => toast.present());
          }
          if(results){
            this.newEditorEmail = '';
            this.loadEditors();
          }
        });
      }
    },
    manageUsers(){
      this.loadViewersAndUsers();
      //this.editAccessVisible = true;
    },
    loadViewersAndUsers(){
      this.loadViewers();
      this.loadEditors();
    },
    updateUser(){
      if (!this.currentUserPhone || UtilityService.validatePhone(this.currentUserPhone)) {
        // if for some reason a bad phone number made it this far, we'll just set email to default pref
        if(this.currentUserPhone == ''){
          this.currentUserPhone = '';
          this.currentUserPreferredComm = '0';
        }
        this.currentUserPhone = UtilityService.stripAllButNumbers(this.currentUserPhone)
        console.log(`Received phone number from form and am about to submit it to the service: ${this.currentUserPhone}`)
        const requestArgs = {
          id: this.user.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: this.currentUserFirstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: this.currentUserLastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number: this.currentUserPhone,
          email: this.user.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          preferred_comm: Number(this.currentUserPreferredComm)
        } as unknown as User
        UserService.update(requestArgs).then( (user: User) => {
          Preferences.set({key: 'user', value: JSON.stringify(user)}).then(() => {
            console.log(`Setting user in session storage with phone number: ${user.phone_number}`)
            toastController.create({ duration: 5000, color: 'success', message: `Preferences updated.`, position: 'top' }).then(toast => toast.present());
            this.editUserVisible = false;
          });
        }).catch( () => {
          toastController.create({ duration: 5000, color: 'warning', message: `Error updating profile or no changes were detected.`, position: 'top' }).then(toast => toast.present());
        });
      } else {
        console.log("INVALID TRYING TO SHOW TOAST")
        console.log(this.currentUserPhone == '')
        console.log(!this.currentUserPhone)
        console.log(this.currentUserPhone)
        toastController.create({ duration: 5000, color: 'warning', message: 'Phone number should contain ten numbers including the 1 + area code. Phone number can be empty or must be valid.', position: 'top' }).then(toast => toast.present());
      }
    },
    loadUserFromSessionStorage(){
      Preferences.get({ key: 'user' }).then((user: any) => {
        this.user = JSON.parse(user.value) as User;
        this.currentEmail = this.user.email;
        this.currentUserFirstName = this.user.first_name;
        this.currentUserLastName = this.user.last_name;
        this.currentUserPhone = this.user.phone_number;
        this.currentUserPreferredComm = this.user.preferred_comm.toString();
        console.log(`loadUserFromSessionStorage() this.user.phone_number: ${this.user.phone_number}`)
        console.log(`loadUserFromSessionStorage() this.currentUserPhone: ${this.currentUserPhone}`)
        //alert('about to push router')
      });
    },
    cancelUpdateUsers(){
      this.editAccessVisible = false;
    },
    cancelUpdateSelf(){
      this.editUserVisible = false;
    },
    logOff(){
      SessionService.logOut();
      this.router.go(0);
    },
    loadViewers(){
      AuthService.getViewersForEntity( this.finalRoute,'*',  (error: any, results: any) => {
        if(error){
          toastController.create({ duration: 5000, color: 'warning', message: 'Error getting viewers for current entity', position: 'top' }).then(toast => toast.present());
        }
        if(results){
          console.log(results)
          this.viewers = results as EntityAuthorization[]
        }
      });
    },
    loadEditors(){
      AuthService.getEditorsForEntity( this.finalRoute,'*',  (error: any, results: any) => {
        if(error){
          toastController.create({ duration: 5000, color: 'warning', message: 'Error getting editors for current entity', position: 'top' }).then(toast => toast.present());
        }
        if(results){
          console.log(results)
          this.editors = results as EntityAuthorization[]
        }
      });
    },
    showUserRegistration(){
      this.createUser = true;
    },
    openUserPrefs(){
      this.loadUserFromSessionStorage();
      console.log(`About to open user prefs. Here is the user phone number in session: ${this.user.phone_number}`)
      console.log(`About to open user prefs. Here is the user phone number in this.currentUserPhone: ${this.currentUserPhone}`)
      this.editUserVisible = true;
    },
    submitNewUserAndAuthenticate(){
      this.currentUserPhone = UtilityService.stripAllButNumbers(this.currentUserPhone);
      const validationErrors = [] as string[];
      if(!this.currentEmail || this.currentEmail === ''){
        validationErrors.push("Email address is required.")
      }
      if(this.currentEmail && !validator.validate(this.currentEmail)){
        validationErrors.push(`${this.currentEmail} is not a valid email address.`)
      }
      if(this.currentUserPhone && !UtilityService.validatePhone(this.currentUserPhone)){
        this.currentUserPhone = UtilityService.formatPhoneNumber(this.currentUserPhone);
        validationErrors.push(`${this.currentUserPhone} is not a valid phone number.`)
      }
      if(validationErrors.length > 0){
        validationErrors.forEach( theErrorMessage => {
          toastController.create({ duration: 5000, color: 'warning', message: theErrorMessage, position: 'top' }).then(toast => toast.present());
        });
      } else {
        console.log(`Will attempt to submit the new user.`)
        const user = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: this.currentUserFirstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: this.currentUserLastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number: this.currentUserPhone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          preferred_comm: this.currentUserPreferredComm,
          email: this.currentEmail
        } as unknown as User;
        AuthService.findOrCreateUserAndAuthenticateWithOTP(user, (error: any, responseUser: User) => {
          if(responseUser) {
            this.user = responseUser;
            this.loginModalVisible = false;
            this.otpModalVisible = true;
            console.log(`Success from findOrCreateUserAndAuthenticateWithOTP: ${JSON.stringify(responseUser)}`);
          }
          if(error){
            toastController.create({ duration: 5000, color: 'warning', message: 'Could not find or register that email address. Please try a different name and email address or try again later.', position: 'top' }).then(toast => toast.present());
            console.log(`Error from findOrCreateUserAndAuthenticateWithOTP: ${error}`);
          }
        });
      }
    },
    submitEmailAddress(){
      if(this.currentEmail == ''){
        const toastMessage = `Email cannot be blank.`;
        toastController.create({ duration: 5000, color: 'warning', message: toastMessage, position: 'top' }).then(toast => toast.present());
      } else if( !validator.validate(this.currentEmail) ){
        const toastMessage = `${this.currentEmail} is not a valid email.`;
        toastController.create({ duration: 5000, color: 'warning', message: toastMessage, position: 'top' }).then(toast => toast.present());
      } else {
        AuthService.canEmailViewEntity( this.currentEmail,this.uuid, this.finalRoute, (error: any, result: any) => {
          if(error){
            toastController.create({ duration: 5000, color: 'warning', message: 'You are not authorized to view this page. If you believe this is in error please contact a manager at your company or support@oscocontrols.com', position: 'top' }).then(toast => toast.present());
          } else {
            console.log(result);
            AuthService.generateOTP( this.currentEmail, (err: any, res: any) => {
              if( res ) {
                this.loginModalVisible = false;
                this.otpModalVisible = true;
                //this.$refs.otp_input.focus();
              } else if ( err ) {
                toastController.create({ duration: 5000, color: 'warning', message: 'Issue generating one time password for user. If you believe this is in error please contact a manager at your company or support@oscocontrols.com', position: 'top' }).then(toast => toast.present());
              }
            });
          }
        });
      }
    },
    authenticate(){
      //console.log(`Attempting to authenticate.`);

      AuthService.authenticate( this.currentEmail, this.otpValue, (err: any, res: any) => {
        if( res ) {
          //console.log(`Response received from authenticate: ${JSON.stringify(res)}`);
          AuthService.isAuthenticated().then( (response) => {
            this.authenticated = response;
            if(!this.authenticated){
              this.loginModalVisible = true;
            } else {
              this.loadUserFromSessionStorage();
              this.loginModalVisible = false;
              this.otpModalVisible = false;
              this.router.go(0)
            }
          }).catch( () => {
            toastController.create({ duration: 5000, color: 'warning', message: 'Error authenticating. Your one time passcode might have expired or there may be a system issue. If you believe this is in error please contact support@oscocontrols.com', position: 'top' }).then(toast => toast.present());
            //console.log(`Error checking if authenticated: ${exc}`);
          });
        } else if (err) {
          toastController.create({ duration: 5000, color: 'warning', message: 'Error authenticating. Your one time passcode might have expired or there may be a system issue. If you believe this is in error please contact support@oscocontrols.com', position: 'top' }).then(toast => toast.present());
        }
      });
    },
  }
});
</script>
<style scoped>
ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}
#otp_container{
  margin:auto;
  width:460px;
  padding-top:25px;
  padding-bottom:25px;
}
</style>
