import axios from 'axios';
import {Delivery} from "@/types/Delivery";
import {DeliveryItem} from "@/types/DeliveryItem";
import {DeliveryKit} from "@/types/DeliveryKit";
class DeliveryDataService {
    get(id: any, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/delivery/${id}?temporary_key=330a6c9b0531`;
        console.log(url);
        axios.get(url).then((response) => {
                console.log(response);
                callback(null,response.data)
            }).catch((error: any) => {
            console.log(error);
                callback(error,null)
            });
    }
    getDeliveryItem(id: any, callback: any): DeliveryItem {
        const url =`${process.env.VUE_APP_API_URL}/mobile/deliveryItem/${id}?temporary_key=330a6c9b0531`;
        const returnDeliveryItem = {} as DeliveryItem;
        axios.get(url)
            .then((response) => {
                //console.log(response.data)
                callback(null,response.data)
                return response.data as DeliveryItem;
            }).catch((error) => {
                console.log(error);
            return returnDeliveryItem as DeliveryItem;
        });
        return returnDeliveryItem;
    }
    getDeliveryKit(id: any, callback: any): DeliveryKit {
        const url =`${process.env.VUE_APP_API_URL}/mobile/deliveryKit/${id}?temporary_key=330a6c9b0531`;
        const returnDeliveryKit = {} as DeliveryKit;
        axios.get(url)
            .then((response) => {
                callback(null,response.data)
                return response.data as DeliveryKit;
            }).catch((error) => {
            console.log(error);
            return returnDeliveryKit as DeliveryKit;
        });
        return returnDeliveryKit;
    }
    list(callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/deliveries?temporary_key=330a6c9b0531`;
        axios.get(url)
            .then((response) => {
                callback(null,response.data)
                return response.data;
            }).catch((error) => {
            callback(error,null)
            return null;
        });
    }
    closedList(callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/closed_deliveries?temporary_key=330a6c9b0531`;
        axios.get(url)
            .then((response) => {
                callback(null,response.data)
                return response.data;
            }).catch((error) => {
            callback(error,null)
            return null;
        });
    }
    delete(id: number,callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/delivery/${id}?temporary_key=330a6c9b0531`;
        axios.delete(url)
            .then((response) => {
                callback(null,response.data)
                return true;
            }).catch((error) => {
            callback(error,null)
            return false;
        });
    }
    update(delivery: Delivery,callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/delivery/${delivery.id}?temporary_key=330a6c9b0531`;
        axios.patch(url, delivery)
            .then((response) => {
                callback(null,response.data)
            }).catch((error) => {
            callback(error,null)
        });
    }
    updateDeliveryItem(deliveryItem: DeliveryItem,callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/deliveryItem/${deliveryItem.id}`;
        axios.patch(url, deliveryItem)
            .then((response) => {
                callback(null,response.data)
            }).catch((error) => {
            callback(error,null)
        });
    }
    uploadDeliveryItemComment(deliveryItem: DeliveryItem,deliveryUUID: string, comment: string, name: string, phone: string, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/create_delivery_item_comment/${deliveryItem.id}?comment=${comment}&name=${name}&phone=${phone}&delivery_uuid=${deliveryUUID}&temporary_key=330a6c9b0531`;
        axios.post(url)
            .then((response) => {
                //console.log(response);
                callback(null,response.data)
            }).catch((error) => {
            callback(error,null)
        });
    }
    getDeliveryWithSchematicsAndManuals(partialUUID: string, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/get_manuals_and_schematics_for_delivery/${partialUUID}?temporary_key=330a6c9b0531`;
        axios.get(url).then((response) => {
                callback(null,response.data)
                return response.data;
            }).catch((error) => {
            callback(error,null)
            return null;
        });
    }
    getDeliveryHistoryByUUID(fullUUID: string, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/delivery_history/${fullUUID}`;
        axios.get(url).then((response) => {
            callback(null,response.data)
            return response.data;
        }).catch((error) => {
            callback(error,null)
            return null;
        });
    }
    getDeliveryCommentsByUUID(fullUUID: string, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/delivery_comments/${fullUUID}`;
        axios.get(url).then((response) => {
            callback(null,response.data)
            return response.data;
        }).catch((error) => {
            callback(error,null)
            return null;
        });
    }
    uploadSchematic(id: string,uuid: string, file: any, fileName: string, callback: any): void {
        const url =`${process.env.VUE_APP_API_URL}/mobile/upload_schematic_for_delivery?temporary_key=330a6c9b0531`;
        let base64WithoutPrefix = file;
        if(file.includes('base64,')){
            base64WithoutPrefix = file.split('base64,')[1];
        }
        const form = new FormData();
        form.append('filename', fileName);
        form.append('delivery_id', id);
        form.append('delivery_uuid', uuid);
        form.append('file', base64WithoutPrefix);
        axios.post(url, form,{headers: {}})
            .then((response) => {
                callback(null,response.data)
            }).catch((error) => {
            callback(error,null)
        });
    }
    uploadManual(id: string, uuid: string, file: any, fileName: string, callback: any): void {
        const url =`${process.env.VUE_APP_API_URL}/mobile/upload_manual_for_delivery?temporary_key=330a6c9b0531`;
        let base64WithoutPrefix = file;
        if(file.includes('base64,')){
            base64WithoutPrefix = file.split('base64,')[1];
        }
        const form = new FormData();
        form.append('filename', fileName);
        form.append('delivery_id', id);
        form.append('delivery_uuid', uuid);
        form.append('file', base64WithoutPrefix);
        axios.post(url, form,{headers: {}})
            .then((response) => {
                callback(null,response.data)
            }).catch((error) => {
            callback(error,null)
        });
    }
    search(uuid: string, searchString: string, callback: any) {
        const url =`${process.env.VUE_APP_API_URL}/mobile/kit_and_item_search/${uuid}?search_string=${searchString}`;
        axios.get(url).then((response) => {
                //console.log(response);
                callback(null,this.parseSearchResults(response.data));
            }).catch((error) => {
            callback(error,null)
        });
    }
    parseSearchResults(results: any){
        console.log("parsing reults!");
        console.log(results);
        const searchResults = [] as any[];
        results.forEach(function (result: any) {
            if('quantity_received_iwo' in result) {
                if('DeliveryKit' in result){

                    searchResults.push({type: 'item', id: result.id, number: (result.number + " (in kit:" + result.DeliveryKit.number + ")")});
                } else {
                    searchResults.push({type: 'item', id: result.id, number: result.number});
                }
            } else {
                if('Parent' in result && result.Parent != null) {
                    searchResults.push({type:'kit',id:result.id,number:(result.number + " (parent of kit:" + result.Parent.number + ")")});
                } else {
                    searchResults.push({type:'kit',id:result.id,number:(result.number)});
                }
            }
        });
        //console.log(searchResults);
        return searchResults;
    }
}
export default new DeliveryDataService();