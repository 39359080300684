import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[14] || (_cache[14] = _createElementVNode("meta", {
      name: "mobile-web-app-capable",
      content: "yes"
    }, null, -1)),
    _cache[15] || (_cache[15] = _createElementVNode("meta", {
      name: "apple-mobile-web-app-capable",
      content: "yes"
    }, null, -1)),
    _cache[16] || (_cache[16] = _createElementVNode("meta", {
      name: "apple-mobile-web-app-status-bar-style",
      content: "black-translucent"
    }, null, -1)),
    _createVNode(_component_ion_page, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, {
          class: "ios",
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_tabs, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_router_outlet),
                _createVNode(_component_ion_tab_bar, {
                  slot: "bottom",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_tab_button, {
                      tab: "Back",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back())),
                      class: "ion-margin-start"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Back")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_tab_button, {
                      tab: "Delivery",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDelivery(this.uuid))),
                      class: "ion-margin-start"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.cube }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("This Delivery")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_searchbar, {
                      debounce: 1000,
                      autocomplete: "on",
                      onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchInputChange($event))),
                      class: "search_style ion-margin-start"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.loginModalVisible,
              backdropDismiss: "false"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, { color: "primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("Please enter your information before proceeding")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("Name:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          ref: "input",
                          type: "text",
                          name: "name",
                          modelValue: this.currentUserName,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.currentUserName) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Phone Number:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          ref: "input",
                          type: "text",
                          name: "phoneNumber",
                          modelValue: this.currentUserPhone,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.currentUserPhone) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_buttons, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              color: "primary",
                              strong: true,
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveUserModal()))
                            }, {
                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode("Confirm")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["isOpen"]),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.searchModalVisible,
              onDidDismiss: _ctx.dismissSearchResults,
              backdropDismiss: "true"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, { color: "primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(this.searchResults.length) + " Kits and Items", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_buttons, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dismissSearchResults()))
                            }, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("Close")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (searchResult) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: searchResult.id
                      }, [
                        (searchResult.type == 'item')
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              onClick: ($event: any) => (_ctx.goToDeliveryItem(searchResult.id)),
                              color: "light"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Item " + _toDisplayString(searchResult.number), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (searchResult.type == 'kit')
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 1,
                              onClick: ($event: any) => (_ctx.goToDeliveryKit(searchResult.id)),
                              color: "light"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Kit " + _toDisplayString(searchResult.number), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["isOpen", "onDidDismiss"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}