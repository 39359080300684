import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "otpCont",
  id: "otp_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_otp = _resolveComponent("otp")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[68] || (_cache[68] = _createElementVNode("meta", {
      name: "mobile-web-app-capable",
      content: "yes"
    }, null, -1)),
    _cache[69] || (_cache[69] = _createElementVNode("meta", {
      name: "apple-mobile-web-app-capable",
      content: "yes"
    }, null, -1)),
    _cache[70] || (_cache[70] = _createElementVNode("meta", {
      name: "apple-mobile-web-app-status-bar-style",
      content: "black-translucent"
    }, null, -1)),
    _createVNode(_component_ion_page, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, {
          class: "ios",
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_tabs, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_router_outlet),
                _createVNode(_component_ion_tab_bar, {
                  slot: "bottom",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_tab_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUserPrefs()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.person }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString((_ctx.user.first_name != '' ? _ctx.user.first_name : 'User')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.canManageUsers && 1 === 3)
                      ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.manageUsers()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.people }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createTextVNode("Manage Access")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_tab_button, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.logOff()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.logOut }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode("Logout")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.otpModalVisible,
              backdropDismiss: "false"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, { color: "primary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                            _createTextVNode("Enter the six digit code you just received")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      color: "light",
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_otp, {
                            "digit-count": 6,
                            "onUpdate:otp": _cache[3] || (_cache[3] = ($event: any) => (_ctx.otpValue = $event))
                          })
                        ], 512)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      color: "light",
                      lines: "none"
                    }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createElementVNode("p", null, "If you prefer to receive your one time password via text message in the future you can adjust your authentication preferences. To do this, authenticate and then click on your profile at the bottom of the screen.", -1)
                      ])),
                      _: 1
                    }),
                    _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_ion_item, {
                      color: "light",
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          disabled: _ctx.otpValue.length != 6,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.authenticate())),
                          slot: "end"
                        }, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("Confirm")
                          ])),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["isOpen"]),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.loginModalVisible,
              backdropDismiss: "false"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, { color: "primary" }, {
                      default: _withCtx(() => [
                        (!_ctx.createUser)
                          ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                              default: _withCtx(() => _cache[31] || (_cache[31] = [
                                _createTextVNode("Please enter your email address")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.createUser)
                          ? (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                              default: _withCtx(() => _cache[32] || (_cache[32] = [
                                _createTextVNode("Please enter your information")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (!_ctx.createUser)
                  ? (_openBlock(), _createBlock(_component_ion_content, {
                      key: 0,
                      class: "ion-padding"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          color: "light",
                          lines: "none",
                          class: "roundedInput"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              label: "Email",
                              ref: "input",
                              type: "text",
                              name: "email",
                              modelValue: this.currentEmail,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.currentEmail) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_ion_item, {
                          color: "light",
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_buttons, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  color: "primary",
                                  strong: true,
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submitEmailAddress()))
                                }, {
                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                    _createTextVNode("Confirm")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_card, { color: "light" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_content, null, {
                              default: _withCtx(() => [
                                _cache[35] || (_cache[35] = _createElementVNode("p", null, [
                                  _createTextVNode("This page is only available to users with a registered email. If you do not have access to this page and need it please contact support@oscocontrols.com."),
                                  _createElementVNode("br"),
                                  _createElementVNode("br"),
                                  _createTextVNode("If you have logged in previously and have entered a valid phone number you can choose to receive your password via SMS message instead of email. ")
                                ], -1)),
                                _cache[36] || (_cache[36] = _createElementVNode("p", null, "If you have never registered with this system, please choose \"Create User\" below. A user will be automatically created for you after you input your name and email address.", -1)),
                                _createVNode(_component_ion_buttons, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_button, {
                                      color: "primary",
                                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showUserRegistration()))
                                    }, {
                                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                                        _createTextVNode("Create User")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.createUser)
                  ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card, { color: "light" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_content, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none",
                                  class: "roundedInput"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[38] || (_cache[38] = [
                                        _createTextVNode("First Name")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      ref: "input",
                                      type: "text",
                                      name: "email",
                                      modelValue: _ctx.currentUserFirstName,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentUserFirstName) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none",
                                  class: "roundedInput"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createTextVNode("Last Name")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      ref: "input",
                                      type: "text",
                                      name: "email",
                                      modelValue: _ctx.currentUserLastName,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currentUserLastName) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none",
                                  class: "roundedInput"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[40] || (_cache[40] = [
                                        _createTextVNode("Email Address")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      ref: "input",
                                      type: "text",
                                      name: "email",
                                      modelValue: _ctx.currentEmail,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.currentEmail) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none",
                                  class: "roundedInput"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                                        _createTextVNode("Phone Number:")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      ref: "input",
                                      type: "text",
                                      modelValue: _ctx.currentUserPhone,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentUserPhone) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _withDirectives(_createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { position: "stacked" }, {
                                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                                        _createTextVNode("Preferred Communication:")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_select, {
                                      label: "Preferred Comm:",
                                      modelValue: _ctx.currentUserPreferredComm,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentUserPreferredComm) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_select_option, { value: "0" }, {
                                          default: _withCtx(() => _cache[43] || (_cache[43] = [
                                            _createTextVNode("Email")
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_select_option, { value: "1" }, {
                                          default: _withCtx(() => _cache[44] || (_cache[44] = [
                                            _createTextVNode("Phone / Text Message")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }, 512), [
                                  [_vShow, _ctx.currentUserPhone && _ctx.currentUserPhone.length > 0]
                                ]),
                                _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_component_ion_item, {
                                  color: "light",
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_buttons, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_button, {
                                          color: "primary",
                                          strong: true,
                                          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.submitNewUserAndAuthenticate()))
                                        }, {
                                          default: _withCtx(() => _cache[45] || (_cache[45] = [
                                            _createTextVNode("Submit New User and Authenticate")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              color: "light",
                              lines: "none",
                              class: "roundedInput"
                            }),
                            _cache[47] || (_cache[47] = _createElementVNode("br", null, null, -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["isOpen"]),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.editUserVisible,
              backdropDismiss: "false"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, null, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("Your Info")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_buttons, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateUser())),
                          slot: "end",
                          fill: "solid",
                          color: "dark"
                        }, {
                          default: _withCtx(() => _cache[49] || (_cache[49] = [
                            _createTextVNode("Save")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.cancelUpdateSelf())),
                          slot: "end",
                          fill: "solid",
                          color: "dark"
                        }, {
                          default: _withCtx(() => _cache[50] || (_cache[50] = [
                            _createTextVNode("Close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[51] || (_cache[51] = [
                            _createTextVNode("First Name:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          ref: "input",
                          type: "text",
                          modelValue: _ctx.currentUserFirstName,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentUserFirstName) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[52] || (_cache[52] = [
                            _createTextVNode("Last Name:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          ref: "input",
                          type: "text",
                          modelValue: _ctx.currentUserLastName,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.currentUserLastName) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { color: "light" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[53] || (_cache[53] = [
                            _createTextVNode("11 Digit Phone Number (e.g. 1.555.310.9140):")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          ref: "input",
                          type: "text",
                          modelValue: _ctx.currentUserPhone,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentUserPhone) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _withDirectives(_createVNode(_component_ion_item, {
                      color: "light",
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[54] || (_cache[54] = [
                            _createTextVNode("Preferred Communication:")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_select, {
                          label: "Preferred Comm:",
                          modelValue: _ctx.currentUserPreferredComm,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.currentUserPreferredComm) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_select_option, { value: "0" }, {
                              default: _withCtx(() => _cache[55] || (_cache[55] = [
                                _createTextVNode("Email")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_ion_select_option, { value: "1" }, {
                              default: _withCtx(() => _cache[56] || (_cache[56] = [
                                _createTextVNode("Phone / Text Message")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, _ctx.currentUserPhone && _ctx.currentUserPhone.length > 0]
                    ]),
                    _createVNode(_component_ion_item, {
                      color: "light",
                      lines: "none",
                      class: "roundedInput"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => _cache[57] || (_cache[57] = [
                            _createTextVNode("Email Address")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          type: "email",
                          enabled: "false"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.currentEmail), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["isOpen"]),
            _createVNode(_component_ion_modal, {
              ref: "modal",
              isOpen: _ctx.editAccessVisible,
              backdropDismiss: "false"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, null, {
                      default: _withCtx(() => _cache[58] || (_cache[58] = [
                        _createTextVNode("Manage User Access")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_buttons, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.cancelUpdateUsers())),
                          slot: "end",
                          fill: "solid",
                          color: "dark"
                        }, {
                          default: _withCtx(() => _cache[59] || (_cache[59] = [
                            _createTextVNode("Close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, {
                      color: "light",
                      class: "ion-padding"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_title, null, {
                              default: _withCtx(() => _cache[60] || (_cache[60] = [
                                _createTextVNode("Viewers (read only)")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_card_content, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewers, (ea) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                color: "light",
                                key: ea.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(ea.email) + " ", 1),
                                  _createVNode(_component_ion_button, {
                                    onClick: ($event: any) => (_ctx.removeUser(ea.id)),
                                    slot: "end"
                                  }, {
                                    default: _withCtx(() => _cache[61] || (_cache[61] = [
                                      _createTextVNode("Remove")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            _createVNode(_component_ion_item, { color: "light" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => _cache[62] || (_cache[62] = [
                                    _createTextVNode("New viewer:")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_ion_input, {
                                  ref: "input",
                                  type: "text",
                                  modelValue: _ctx.newViewerEmail,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.newViewerEmail) = $event))
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_ion_button, {
                                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.addViewer()))
                                }, {
                                  default: _withCtx(() => _cache[63] || (_cache[63] = [
                                    _createTextVNode("Add")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card, {
                      color: "light",
                      class: "ion-padding"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_title, null, {
                              default: _withCtx(() => _cache[64] || (_cache[64] = [
                                _createTextVNode("Editors (Can modify users and data)")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_card_content, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editors, (ea) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                color: "light",
                                key: ea.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(ea.email) + " ", 1),
                                  _createVNode(_component_ion_button, {
                                    onClick: ($event: any) => (_ctx.removeUser(ea.id)),
                                    slot: "end"
                                  }, {
                                    default: _withCtx(() => _cache[65] || (_cache[65] = [
                                      _createTextVNode("Remove")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            _createVNode(_component_ion_item, { color: "light" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => _cache[66] || (_cache[66] = [
                                    _createTextVNode("New editor:")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_ion_input, {
                                  ref: "input",
                                  type: "text",
                                  modelValue: _ctx.newEditorEmail,
                                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.newEditorEmail) = $event))
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_ion_button, {
                                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.addEditor()))
                                }, {
                                  default: _withCtx(() => _cache[67] || (_cache[67] = [
                                    _createTextVNode("Add")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["isOpen"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}