import {Preferences} from "@capacitor/preferences";

class SessionService {
    getItemJSON(key: string): any {
        Preferences.get({ key: key }).then((result) => {
            console.log(`In SessionService.getItemJSON('${key}' and value is: ${result}`)
            console.log(result)
            if(result.value){
                return result.value as any;
            } else {
                return null
            }
        });
    }
    setItemJSON(key: string, value: any): any {
        Preferences.set({ key: key, value: JSON.stringify(value) }).then(() => {
            return true;
        });
    }
    removeItem(key: string) {
        Preferences.remove({ key: key }).then( () => {
            return true;
        });
    }
    logOut() {
        this.removeItem("user");
        this.removeItem("refresh_token");
        this.removeItem("access_token");
        this.removeItem("expires_at");
    }
}
export default new SessionService();